export const ALL_LESSONS_REQUEST = 'ALL_LESSONS_REQUEST';
export const ALL_LESSONS_SUCCESS = 'ALL_LESSONS_SUCCESS';
export const ALL_LESSONS_FAIL = 'ALL_LESSONS_FAI';

export const LESSON_DETAILS_REQUEST = 'LESSON_DETAILS_REQUEST';
export const LESSON_DETAILS_SUCCESS = 'LESSON_DETAILS_SUCCESS';
export const LESSON_DETAILS_FAIL = 'LESSON_DETAILS_FAIL';

export const CREATE_LESSON_REQUEST = 'CREATE_LESSON_REQUEST';
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS';
export const CREATE_LESSON_FAIL = 'CREATE_LESSON_FAIL';
export const CREATE_LESSON_RESET = 'CREATE_LESSON_RESET';

export const UPDATE_LESSON_REQUEST = 'UPDATE_LESSON_REQUEST';
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS';
export const UPDATE_LESSON_FAIL = 'UPDATE_LESSON_FAIL'; 
export const UPDATE_LESSON_RESET = 'UPDATE_LESSON_RESET';

export const DELETE_LESSON_REQUEST = 'DELETE_LESSON_REQUEST';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_LESSON_FAIL = 'DELETE_LESSON_FAIL';
export const DELETE_LESSON_RESET = 'DELETE_LESSON_RESET';

export const LESSON_FILTER_REQUEST = 'LESSON_FILTER_REQUEST';
export const LESSON_FILTER_SUCCESS = 'LESSON_FILTER_SUCCESS';
export const LESSON_FILTER_FAIL = 'LESSON_FILTER_FAIL';



export const CLEAR_ERRORS = 'CLEAR_ERRORS';
