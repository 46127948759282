export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';


export const DASHBOARD_DETAILS_REQUEST = 'DASHBOARD_DETAILS_REQUEST';
export const DASHBOARD_DETAILS_SUCCESS = 'DASHBOARD_DETAILS_SUCCESS';
export const DASHBOARD_DETAILS_FAIL = 'DASHBOARD_DETAILS_FAIL';

export const CREATE_DASHBOARD_REQUEST = 'CREATE_DASHBOARD_REQUEST';
export const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS';
export const CREATE_DASHBOARD_FAIL = 'CREATE_DASHBOARD_FAIL';
export const CREATE_DASHBOARD_RESET = 'CREATE_DASHBOARD_RESET';

export const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_FAIL = 'DELETE_DASHBOARD_FAIL';
export const DELETE_DASHBOARD_RESET = 'DELETE_DASHBOARD_RESET';

export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_DASHBOARD_REQUEST';
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS';
export const UPDATE_DASHBOARD_FAIL = 'UPDATE_DASHBOARD_FAIL';
export const UPDATE_DASHBOARD_RESET = 'UPDATE_DASHBOARD_RESET';


export const CLEAR_ERRORS = 'CLEAR_ERRORS';